import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1600.000000 1600.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1600.000000) scale(0.100000,-0.100000)">
         <path d="M7845 12759 c-364 -24 -690 -116 -1014 -285 -719 -375 -1223 -1078
-1338 -1866 -10 -64 -15 -120 -12 -123 3 -3 83 0 176 7 128 9 209 9 324 1
l153 -11 43 30 c37 27 43 38 53 87 20 102 88 300 144 414 98 204 260 419 423
561 606 530 1450 608 2138 198 354 -211 636 -560 775 -957 53 -154 112 -223
228 -271 90 -37 186 -33 282 11 140 63 213 175 213 325 -1 94 -52 249 -153
455 -274 564 -745 1005 -1325 1243 -347 142 -740 206 -1110 181z"/>
<path d="M5478 10379 c-568 -82 -1090 -348 -1472 -750 -376 -396 -608 -863
-691 -1394 -23 -143 -30 -445 -16 -601 100 -1069 859 -1958 1901 -2228 224
-58 335 -70 620 -71 290 0 372 9 610 67 317 76 701 256 830 387 89 91 150 242
150 371 0 75 -27 176 -67 253 -34 66 -94 129 -703 738 -532 534 -675 671 -715
690 -42 20 -66 24 -145 24 -80 0 -103 -4 -144 -24 -63 -31 -118 -86 -153 -153
-23 -45 -27 -66 -27 -138 -1 -155 -35 -113 597 -747 309 -309 559 -564 556
-567 -22 -23 -244 -100 -374 -131 -167 -39 -267 -48 -467 -42 -279 8 -467 54
-718 173 -199 95 -345 199 -500 354 -257 256 -429 580 -503 945 -19 95 -22
141 -22 340 1 261 13 340 86 560 205 616 733 1082 1365 1205 223 43 494 43
707 -1 56 -11 113 -23 127 -25 14 -3 66 -19 115 -36 438 -149 815 -479 1024
-896 47 -94 82 -178 86 -207 1 -11 14 -58 28 -105 44 -141 66 -302 77 -553 5
-128 16 -277 25 -332 68 -441 256 -865 542 -1220 161 -201 430 -436 648 -568
536 -324 1172 -440 1789 -326 967 179 1762 932 1995 1889 44 184 61 313 68
521 14 448 -69 822 -271 1224 -136 269 -263 444 -480 660 -374 372 -835 611
-1356 701 -331 58 -836 35 -1056 -47 -59 -22 -143 -93 -179 -149 -30 -47 -55
-132 -55 -186 0 -66 36 -167 79 -222 67 -84 185 -142 290 -142 25 0 93 9 150
20 814 158 1634 -261 1987 -1015 53 -114 116 -311 140 -440 29 -154 27 -520
-4 -665 -141 -655 -590 -1164 -1208 -1369 -578 -193 -1206 -84 -1689 294 -107
83 -274 259 -358 376 -85 119 -197 340 -241 478 -64 196 -77 281 -86 561 -16
478 -84 772 -265 1137 -202 406 -490 734 -870 988 -323 215 -679 346 -1088
400 -167 21 -502 19 -669 -6z"/>
<path d="M8435 10087 c-93 -31 -179 -109 -223 -206 -22 -46 -27 -70 -27 -141
0 -75 4 -93 32 -152 39 -82 99 -142 183 -182 56 -27 76 -31 145 -30 145 1 267
75 330 201 27 53 30 67 30 158 0 87 -3 107 -26 156 -36 77 -87 130 -161 170
-56 29 -73 33 -153 36 -55 2 -106 -2 -130 -10z m166 -179 c80 -27 129 -94 129
-176 0 -173 -190 -255 -318 -138 -37 33 -55 76 -55 131 -1 131 123 225 244
183z"/>
<path d="M5602 9560 c-29 -4 -56 -11 -59 -14 -8 -8 79 -258 128 -367 191 -426
519 -814 899 -1066 47 -31 90 -62 96 -69 14 -17 237 -133 339 -177 145 -62
286 -105 504 -153 l24 -5 -6 198 c-5 202 -18 290 -67 453 -25 84 -72 141 -138
165 -79 30 -252 121 -342 181 -263 175 -482 424 -617 701 -51 105 -35 95 -218
135 -101 22 -447 34 -543 18z"/>
<path d="M10075 9114 c-139 -19 -191 -29 -260 -49 -297 -84 -550 -267 -717
-517 -154 -229 -222 -451 -222 -723 -1 -231 38 -395 140 -596 181 -356 504
-600 904 -686 120 -25 381 -25 505 1 633 132 1069 687 1041 1326 -29 684 -563
1216 -1246 1244 -63 2 -128 2 -145 0z m124 -558 c39 -20 63 -61 65 -108 l1
-40 80 6 c44 3 122 8 173 12 65 5 92 10 92 19 0 19 58 65 82 65 28 0 78 -53
78 -83 0 -14 -12 -40 -26 -57 l-27 -31 23 -130 c22 -118 25 -127 36 -101 31
74 121 108 198 73 109 -48 117 -198 14 -261 -18 -11 -43 -20 -55 -20 -13 0
-23 -6 -24 -12 0 -7 -6 -67 -12 -133 l-13 -121 36 -16 c118 -57 74 -248 -57
-248 -67 0 -133 67 -133 135 0 38 32 92 68 112 l28 16 -33 175 c-18 96 -34
176 -35 177 -2 1 -103 -30 -225 -68 l-223 -71 0 -40 c0 -24 -9 -55 -20 -74
l-21 -34 162 -264 161 -264 48 6 c82 11 149 -39 166 -123 9 -44 -26 -117 -68
-143 -65 -39 -159 -17 -199 47 -29 46 -23 132 11 168 12 13 20 30 17 37 -4 12
-305 508 -313 517 -2 2 -19 -4 -38 -13 -44 -21 -91 -20 -142 3 -56 25 -87 71
-92 137 -8 101 63 178 164 178 60 0 120 -33 144 -80 8 -16 15 -31 16 -32 2 -3
145 40 405 124 l45 14 -31 168 c-27 145 -33 167 -50 170 -11 2 -32 16 -46 32
l-25 30 -170 -11 c-167 -12 -169 -12 -196 -40 -45 -49 -108 -62 -159 -35 -27
14 -29 12 -199 -159 -95 -96 -171 -178 -169 -183 2 -5 78 -178 169 -383 l164
-374 67 0 c53 0 74 -5 96 -21 72 -53 99 -141 65 -215 -61 -137 -227 -156 -316
-36 -17 22 -21 43 -21 97 l0 69 -105 46 c-77 35 -106 43 -109 33 -2 -7 -17
-25 -33 -40 -85 -79 -218 -18 -218 100 0 41 5 50 44 88 l45 43 -23 83 -22 83
-60 6 c-69 7 -87 15 -133 62 -95 99 -61 266 66 324 64 29 116 27 184 -7 l41
-21 33 38 c20 22 32 45 29 55 -3 9 -29 69 -58 135 -38 85 -58 119 -72 121 -56
8 -66 14 -80 47 -38 91 77 159 141 84 l26 -32 112 7 c62 4 147 9 190 13 l77 6
0 48 c0 75 52 127 128 128 18 1 46 -6 61 -13z"/>
<path d="M9786 8356 c-145 -10 -159 -13 -166 -31 -4 -12 -15 -26 -23 -33 -13
-11 -9 -28 40 -140 31 -70 57 -129 60 -132 6 -7 342 333 337 342 -6 9 -34 9
-248 -6z"/>
<path d="M9661 7936 l-33 -34 18 -33 c45 -89 35 -176 -28 -245 -22 -24 -48
-46 -59 -49 -24 -8 -24 -12 2 -112 20 -77 24 -83 48 -83 57 0 121 -64 121
-120 0 -25 9 -31 105 -73 l105 -47 41 41 40 40 -164 374 -164 374 -32 -33z"/>
<path d="M10790 7944 c0 -12 51 -277 57 -299 6 -20 6 -20 13 0 4 11 10 61 14
110 4 50 9 102 12 117 4 21 1 28 -18 33 -13 4 -35 16 -50 27 -16 11 -28 16
-28 12z"/>
<path d="M8432 8358 c-6 -9 -5 -39 4 -93 8 -44 18 -200 23 -348 6 -180 13
-272 21 -282 6 -7 48 -26 93 -41 45 -15 106 -37 136 -50 89 -40 115 -27 100
50 -29 154 -19 386 25 559 15 57 20 98 15 108 -9 17 -167 67 -297 94 -89 18
-107 19 -120 3z"/>
<path d="M9738 6461 c-14 -19 -12 -32 14 -134 16 -61 35 -118 42 -125 52 -52
587 -67 686 -19 23 12 25 18 24 72 -2 87 -22 173 -43 185 -12 6 -39 6 -77 -1
-106 -18 -357 -12 -464 11 -158 35 -165 35 -182 11z"/>
<path d="M5899 5197 c-98 -41 -147 -86 -197 -181 -24 -46 -27 -61 -27 -156 0
-103 1 -107 43 -195 69 -142 185 -327 288 -457 432 -541 1047 -882 1729 -958
135 -15 433 -12 575 5 848 105 1594 636 1965 1400 82 168 145 330 145 372 0
38 -34 91 -62 97 -13 3 -115 7 -228 10 -113 2 -245 10 -293 17 -83 11 -90 11
-115 -8 -16 -12 -55 -75 -97 -159 -137 -273 -307 -479 -540 -655 -187 -142
-462 -271 -680 -319 -317 -71 -664 -54 -965 46 -464 154 -842 481 -1061 917
-61 121 -103 168 -187 209 -57 28 -79 33 -152 36 -71 2 -94 -1 -141 -21z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
